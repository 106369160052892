import config from '@/services/config'
import axios from 'axios'
import jsPdf from 'jspdf'
import html2canvas from 'html2canvas'
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setuserData (response, abilities) {
    const user = response.user
    const userData = {
      account_company: response.account_company,
      email: user.email,
      first_name: user.first_name,
      groups: user.groups,
      id: user.id,
      is_active: user.is_active,
      is_verified: user.is_verified ? user.is_verified : false,
      is_superuser: user.is_superuser ? user.is_superuser : false,
      last_name: user.last_name,
      main_group: user.main_group,
      max_views: user.max_views,
      user_permissions: user.user_permissions,
      username: user.username,
      ability: abilities,
      profile_image: user.profile_image,
      question_text: user.question_text,
      has_payment_data: user.has_payment_data,
      payment_data: user.has_payment_data ? user.payment_data : null,
    }
    localStorage.setItem('accept_tyc', user.accept_tyc ? user.accept_tyc : false);
    localStorage.setItem('userData', JSON.stringify(userData))
  },
  setPermissions(user) {
    const permissions = user.all_permissions.map(permission => permission.codename)
    localStorage.setItem('permissions', JSON.stringify(permissions))
  },
  getPermissions() {
    if (localStorage.getItem('permissions') === null) localStorage.setItem('permissions', JSON.stringify([]))
    return JSON.parse(localStorage.getItem('permissions'));
  },
  hasPermission(permission) {
    if (localStorage.getItem('permissions') === null) localStorage.setItem('permissions', JSON.stringify([]))
    return JSON.parse(localStorage.getItem('permissions')).indexOf(permission) !== -1;
  },
  getRandom(min, max) {
    return Math.floor((Math.random() * (max - min)) + min);
  },
  getAssetUrl(path) {
    return config.urlCore + path;
  },
  getImage(path) {
    return path ? this.getAssetUrl(path) : ''
  },
  getUserData () {
    return JSON.parse(localStorage.getItem('userData'))
  },
  updatePaymentData(payment_data) {
    const user = this.getUserData()
    user.has_payment_data = true
    user.payment_data = payment_data
    localStorage.setItem('userData', JSON.stringify(user))
  },
  capitalize (s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  },
  getURL (network, username, is_channel = false) {
    const user = network === 'youtube' ? (is_channel ? `channel/${username}` : `c/${username}`) : network === 'tiktok' ? `@${username}` : network === 'snapchat' ? `add/${username}` : username
    if (network === 'linkedin') return `https://www.${network}.com/in/${username}`
    if (network === 'spotify') return `https://open.spotify.com/user/${username}`
    return `https://www.${network}.com/${user}`
  },
  getNetworkColor (network) {
    if (network === 'facebook') return 'color:#3b5998'
    if (network === 'instagram') return 'color:#d6249f'
    if (network === 'linkedin') return 'color:#0e76a8'
    if (network === 'twitch') return 'color:#6441a5'
    if (network === 'twitter') return 'color:#00acee'
    if (network === 'youtube') return 'color:#c4302b'
    if (network === 'tiktok') return 'color:#c4302b'
    if (network === 'spotify') return 'color:#c4302b'
    if (network === 'pinterest') return 'color:#c4302b'
    if (network === 'blog') return 'color:#c4302b'
    if (network === 'snapchat') return 'color:#c4302b'
  },
  getLocalNetworkName (network) {
    if (network === 'facebook') return 'facebook'
    if (network === 'ig') return 'instagram'
    if (network === 'instagram') return 'instagram'
    if (network === 'linkedin') return 'linkedin'
    if (network === 'twitch') return 'twitch'
    if (network === 'twitter') return 'twitter'
    if (network === 'yt') return 'youtube'
    if (network === 'youtube') return 'youtube'
    if (network === 'tt') return 'tiktok'
    if (network === 'tiktok') return 'tiktok'
    if (network === 'spotify') return 'spotify'
    if (network === 'pinterest') return 'pinterest'
    if (network === 'blog') return 'blog'
    if (network === 'snapchat') return 'snapchat'
    if (network === 'tr') return 'twitter'
    if (network === 'tw') return 'twitch'
  },
  removeLocal () {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    // Remove userData from localStorage
    localStorage.removeItem('userData')
    // Redirect to login page
    this.$router.push({ name: 'auth-login' })
  },
  checkGroup (group, company) {
    if (group === null) return false
    if (group.name === 'brand' && company === null) return false
    return true
  },
  checkVerified () {
    const user = this.getUserData()
    return user.is_verified
  },
  getDate (unix) {
    const fecha = new Date(unix * 1000)
    return fecha.getTime()
    //return `${fecha.getDay()}/${fecha.getMonth()}/${fecha.getFullYear()}`
  },
  getDatev2 (date) {
    const month = date.getMonth() + 1;
    const string_month = month < 10 ? `0${month}` : `${month}`

    return `${date.getDate()}/${string_month}/${date.getFullYear()}`
  },
  getDatev3(date) {
    const newDate = new Date(date);
    const options = {hour: 'numeric', minute: 'numeric',year: 'numeric', month: 'long', day: 'numeric'}
    return newDate.toLocaleDateString('es-ES', options);
  },
  getDatev4 (date) {
    const month = date.getMonth() + 1;
    const string_month = month < 10 ? `0${month}` : `${month}`

    return `${date.getFullYear()}-${string_month}-${date.getDate()}`
  },
  getHourMinutes(date) {
    const newDate = new Date(date);
    const withPmAm = newDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return withPmAm
  },
  getCategories (data, key) {
    return data.map(item => {
      return item[key]
    })
  },
  getSeries (data, key) {
    return data.map(item => {
      return item[key]
    })
  },
  getFormat (num) {
    if (num >= 1000000) {
      const val = Math.round(num / 1000) / 1000
      return  `${Intl.NumberFormat('en-US').format(this.decimalFixed(val, 2))}M`
    }
    if (num >= 1000) {
      const val = Math.round(num / 10) / 100
      return `${val}K`
    }
    if (num === 0) return '0'
    return this.numberFormat(num.toString())
  },
  separatebycomma (num) {
    if (num === null) return '0'
    const result = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return result
  },
  toCurrency(num) {
    const val = Number(num).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
    return val.slice(0,-3)
  },
  convertData (data) {
    const keys = Object.keys(data)
    const obj = []
    for (let index = 0; index < keys.length; index++) {
      obj.push({name: keys[index], value: data[keys[index]].value})
    }
    return obj
  },
  numberFormat(number) {
    if (typeof number === 'string') number = parseFloat(number)
    return new Intl.NumberFormat().format(number)
  },
  getColorSentiment(sentiment) {
    const sentiments = [
      {text: 'neutral', color: 'text-warning'},
      {text: 'negative', color: 'text-danger'},
      {text: 'positive', color: 'text-primary'},
    ]

    const element = sentiments.find(item => item.text === sentiment)

    if (element)
      return element.color

    return 'text-info'
  },
  getLanguage (code) {
    const languages = require('./languages.json')
    const language = languages.find(item => { if (item.code === code) return item })
    if (language)
      return language.name

    return code
  },
  getCountry (code) {
    const countries = require('./countries.json')
    const country = countries.find(country => { if (country['alpha-2'] === code.toUpperCase()) return country })
    return country.name
  },
  decimalFixed (decimal, decimals) {
    return decimal.toFixed(decimals)
  },
  getParamNetwork (net) {
    const params = {
      youtube: 'yt',
      instagram: 'ig',
      tiktok: 'tt',
      twitch: 'tw',
      twitter: 'tr'
    }
    return params[net]
  },
  isUrl(string) {
    let url = false;
      
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }

    return url.protocol === "http:" || url.protocol === "https:";
  },
  async checkResourceExists(url) {
    if (!this.isUrl(url)) return false;

    return await axios.get(url)
      .then(function() {
        return true
      })
      .catch(() => {
        return false
      });
  },
  getParamUrl (url, name_param) {
    const url_object = new URL(url);
    const c = url_object.searchParams.get(name_param);
    return c
  },
  urlImagetoBase64(url_image, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url_image);
    xhr.responseType = 'blob';
    xhr.send();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = function () {
      callback(url_image);
    }
  },
  print(orientation, filename, element, callback, format = 'pdf') {
    element = element || document.body

    const noscript = document.getElementsByTagName('noscript')[0]

    if (noscript) noscript.remove()

    setTimeout(function() {
      const margin = 15;

      html2canvas(element)
        .then(function (canvas) {
          canvas.getContext('2d');

          const imgData = canvas.toDataURL("image/jpeg", 1.0);

          if (format === 'pdf') {
            const pdf = new jsPdf(orientation, 'pt', [(canvas.width + (margin * 2)), (canvas.height + ((margin * 2)))]);

            pdf.addImage(imgData, 'JPG', margin, margin, canvas.width, canvas.height);

            pdf.save(`${filename}.pdf`);
            callback(true)
          } else {
            const a = document.createElement('a');
            a.href = imgData;
            a.download = `${filename}.png`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            callback(true)
          }

          const noscript = document.getElementsByTagName('noscript')[0]

          if (noscript === undefined) {
            const tag = document.createElement('noscript');
            const text = document.createTextNode("We're sorry but BrandMe Platform doesn't work properly without JavaScript enabled. Please enable it to continue.");
            tag.appendChild(text);
            const element = document.body;
            element.appendChild(tag);
          }
        });
    }, 100);
  },
  trimNumber(stringNumber) {
    if (stringNumber === '') return 0;
    return parseFloat(stringNumber.replace(/,/g, ''));
  },
  getTypeInfluencerTraduction(category) {
    const types = {
      'top-celebrity': 'influencerCategory.top-celebrity',
      'top-influencer-celebrity': 'influencerCategory.top-influencer-celebrity',
      'microinfluencer': 'influencerCategory.microinfluencer',
      'macroinfluencer': 'influencerCategory.macroinfluencer',
      'nanoinfluencer': 'influencerCategory.nanoinfluencer',
    }

    if (types[category]) return types[category]
    return ''
  },
  getTypeInfluencer(category) {
    const types = {
      'top-celebrity': 'Top Celebrity',
      'top-influencer-celebrity': 'Top Influencer / Celebridad',
      'microinfluencer': 'Micro-influencer',
      'macroinfluencer': 'Macro-influencer',
      'nanoinfluencer': 'Nanoinfluencer',
    }

    if (types[category]) return types[category]
    return ''
  },
  getTypeInfluencerNumber(category) {
    const types = {
      'top-celebrity': 1,
      'top-influencer-celebrity': 2,
      'macroinfluencer': 3,
      'microinfluencer': 4,
      'nanoinfluencer': 5,
    }

    if (types[category]) return types[category]
    return 6
  },
  getStatus(status) {
    const types = {
      'approved': 'campaigns.approved',
      'pending': 'campaigns.pending',
      'rejected': 'campaigns.rejected',
      'onboarding': 'campaigns.onboarding',
      'trading': 'campaigns.trading',
      'producing': 'campaigns.producing',
      'submitted': 'campaigns.submitted',
      'published': 'campaigns.published',
    }
    if (types[status]) return types[status]
    return ''
  },
  getStatusContent(status) {
    if (status === 'content_pending') return 'text-warning'
    if (status === 'content_producing') return 'text-success'
    if (status === 'content_submitted') return 'text-primary'
    if (status === 'content_rejected') return 'text-danger'
    if (status === 'content_published') return 'text-success'
  },
  getClassNetworkSpan(network) {
    if (network === 'facebook') return 'icomoon-Facebook-2 facebook'
    if (network === 'instagram') return 'icomoon-instagram1 instagram'
    if (network === 'linkedin') return 'icomoon-Linkedin linkedin'
    if (network === 'twitch') return 'iconNetwork-twitch twitch'
    if (network === 'twitter') return 'icomoon-Twitter-1 twitter'
    if (network === 'youtube') return 'icomoon-youtube youtube'
    if (network === 'tiktok') return 'icomoon-tiktok tiktok'
    if (network === 'spotify') return 'iconNetwork-spotify spotify'
    if (network === 'pinterest') return 'icomoon-pinterest2 pinterest'
    if (network === 'blog') return 'iconNetwork-blogger blog'
    if (network === 'snapchat') return 'iconNetwork-snapchat-ghost snapchat'
  },
  getColorFollowers(network) {
    if (network === 'tiktok') return 'text-black'
    return network
  },
  getIconInteractions(name) {
    if (name === 'number_of_likes') return 'heart-fill'
    if (name === 'number_of_comments') return 'chat'
    if (name === 'number_of_shares') return 'box-arrow-up-right'
    if (name === 'number_of_rts') return 'box-arrow-up'
    if (name === 'number_of_times_saved') return 'bookmark'
    if (name === 'number_of_times_favs') return 'bookmark'
    if (name === 'number_of_reproductions') return 'play-circle'
    if (name === 'number_of_views') return 'eye'
    if (name === 'number_of_clicks_to_stickers') return 'cursor'
    if (name === 'number_of_clicks_to_the_link') return 'link-45deg'
    if (name === 'number_of_dislikes') return 'hand-thumbs-down'
    if (name === 'number_of_impressions') return 'graph-up'
    if (name === 'number_of_prints') return 'printer'
    if (name === 'number_of_responses') return 'question-circle'
    if (name === 'scope_number') return 'people'
    if (name === 'number_of_interactions') return 'bar-chart-line'
  },
  getBgNetwork(network) {
    if (network === 'facebook') return 'facebook-bg'
    if (network === 'instagram') return 'instagram-bg'
    if (network === 'linkedin') return 'linkedin-bg'
    if (network === 'twitch') return 'twitch-bg'
    if (network === 'twitter') return 'twitter-bg'
    if (network === 'youtube') return 'youtube-bg'
    if (network === 'tiktok') return 'tiktok-bg'
    if (network === 'spotify') return 'spotify-bg'
    if (network === 'pinterest') return 'pinterest-bg'
    if (network === 'blog') return 'blog-bg'
    if (network === 'snapchat') return 'snapchat-bg'
  },
  getOutlineNetwork(network) {
    if (network === 'facebook') return 'facebook-outline'
    if (network === 'instagram') return 'instagram-outline'
    if (network === 'linkedin') return 'linkedin-outline'
    if (network === 'twitch') return 'twitch-outline'
    if (network === 'twitter') return 'twitter-outline'
    if (network === 'youtube') return 'youtube-outline'
    if (network === 'tiktok') return 'tiktok-outline'
    if (network === 'spotify') return 'spotify-outline'
    if (network === 'pinterest') return 'pinterest-outline'
    if (network === 'blog') return 'blog-outline'
    if (network === 'snapchat') return 'snapchat-outline'
  },
  getClassNetworkIconBorder(network) {
    if (network === 'facebook') return 'icomoon-Facebook-2 facebook-border'
    if (network === 'instagram') return 'icomoon-instagram1 instagram-border'
    if (network === 'linkedin') return 'icomoon-Linkedin linkedin-border'
    if (network === 'twitch') return 'iconNetwork-twitch twitch-border'
    if (network === 'twitter') return 'icomoon-Twitter-1 twitter-border'
    if (network === 'youtube') return 'icomoon-youtube youtube-border'
    if (network === 'tiktok') return 'icomoon-tiktok tiktok-border'
    if (network === 'spotify') return 'iconNetwork-spotify spotify-border'
    if (network === 'pinterest') return 'icomoon-pinterest2 pinterest-border'
    if (network === 'blog') return 'iconNetwork-blogger blog-border'
    if (network === 'snapchat') return 'iconNetwork-snapchat-ghost snapchat-border'
  },
  getContent(type) {
    if (type === 'photo') return 'camera'
    if (type === 'video') return 'camera-video'
    if (type === 'story') return 'clock-history'
    if (type === 'streaming') return 'display'
    if (type === 'instagram_tv') return 'instagram'
    if (type === 'reel') return 'camera-reels'
    if (type === 'post') return 'images'
    if (type === 'snap') return 'file-earmark-play' 
    if (type === 'podcast') return 'soundwave'
    if (type === 'clip') return 'joystick'
    return 'award'
  },
  getIconInterest(item) {
    if (item === "Business & Careers") return 'briefcase-fill'
    if (item === 'Modeling') return 'camera-reels-fill'
    if (item === 'Fashion') return 'star-fill'
    if (item === 'Nature & landscapes') return 'image-alt'
    if (item === 'Travel') return 'geo-alt-fill'
    if (item === 'Photography') return 'camera-fill'
    if (item === 'Fitness & Gym') return 'bicycle'
    if (item === 'Trainers & Coaches') return 'bicycle'
    if (item === 'Cinema & Actors/actresses') return 'film'
    if (item === 'Management & Marketing') return 'globe'
    if (item === 'Beauty') return 'flower1'
    if (item === 'Lifestyle') return 'emoji-sunglasses'
    if (item === 'Music') return 'music-note-beamed'
    if (item === 'Family') return 'house'
    if (item === 'Finance & Economics') return 'graph-up'
    if (item === 'Art/Artists') return 'brush'
    if (item === 'Literature & Journalism') return 'book-fill'
    if (item === 'Sports with a ball') return 'circle'
    if (item === 'Kids & Toys') return 'puzzle-fill'      
    if (item === 'Shows') return 'calendar-day'
    if (item === 'Humor & Fun & Happiness') return 'emoji-laughing'
    if (item === 'Winter sports') return 'asterisk'
    if (item === 'Adult content') return 'x'
    if (item === 'Tobacco & Smoking') return 'truck'
    if (item === 'Alcohol') return 'cup-straw'
    if (item === 'Extreme Sports & Outdoor activity') return 'cloud'
    if (item === 'Machinery & Technologies') return 'cpu-fill'
    if (item === 'Luxury') return 'gem'
    if (item === 'Computers & Gadgets') return 'laptop'
    if (item === 'Clothing & Outfits') return 'bag'
    if (item === 'Mobile related') return 'music-player'     
    if (item === 'Science') return 'tree'
    if (item === 'Racing Sports') return 'trophy-fill'
    if (item === 'Cars & Motorbikes') return 'stoplights'
    if (item === 'Food & Cooking') return 'egg-fried'
    if (item === 'Architecture & Urban Design') return 'bricks'
    if (item === 'Animals') return 'bug-fill'
    if (item === 'Comics & sketches') return 'book-half'
    if (item === 'Health & Medicine') return 'plus-square'
    if (item === 'Shopping & Retail') return 'shop'
    if (item === 'Accessories & Jewellery') return 'watch'
    if (item === 'Water sports') return 'droplet-fill'
    if (item === 'DIY & Design') return 'building'
    if (item === 'Sweets & Bakery') return 'heart'  
  },
  getIconCategories(category, network) {
    if (network === 'instagram') {
      if (category.id  === 1007) return 'bag'
      if (category.id  === 1022) return 'plus-square'
      if (category.id  === 1047) return 'truck'
      if (category.id  === 1009) return 'geo-alt-fill'
      if (category.id  === 1039) return 'camera-reels-fill'
      if (category.id  === 1021) return 'star-fill'
      if (category.id  === 1025) return 'image-alt'
      if (category.id  === 1038) return 'camera-fill'
      if (category.id  === 1023) return 'bicycle'
      if (category.id  === 1031) return 'bicycle'
      if (category.id  === 1032) return 'film'
      if (category.id  === 1044) return 'globe'
      if (category.id  === 1020) return 'flower1'
      if (category.id  === 1041) return 'emoji-sunglasses'
      if (category.id  === 1027) return 'music-note-beamed'
      if (category.id  === 1042) return 'house-door'
      if (category.id  === 1006) return 'graph-up'
      if (category.id  === 1002) return 'brush'
      if (category.id  === 1004) return 'book-fill'
      if (category.id  === 1013) return 'circle'
      if (category.id  === 1046) return 'puzzle-fill'      
      if (category.id  === 1017) return 'calendar-day'
      if (category.id  === 1036) return 'emoji-laughing'
      if (category.id  === 1012) return 'asterisk'
      if (category.id  === 1048) return 'x'
      if (category.id  === 1001) return 'cup-straw'
      if (category.id  === 1014) return 'cloud'
      if (category.id  === 1030) return 'cpu-fill'
      if (category.id  === 1043) return 'gem'
      if (category.id  === 1034) return 'laptop'
      if (category.id  === 1010) return 'music-player'     
      if (category.id  === 1015) return 'trophy-fill'
      if (category.id  === 1018) return 'stoplights'
      if (category.id  === 1035) return 'egg-fried'
      if (category.id  === 1003) return 'bricks'
      if (category.id  === 1026) return 'bug-fill'
      if (category.id  === 1016) return 'book-half'
      if (category.id  === 1028) return 'shop'
      if (category.id  === 1000) return 'watch'
      if (category.id  === 1011) return 'droplet-fill'
      if (category.id  === 1037) return 'building'
      if (category.id  === 1019) return 'heart'  
      if (category.id  === 1033) return 'award'
      if (category.id  === 6) return 'brightness-alt-high'
      if (category.id  === 8) return 'dice5'  
      if (category.id  === 23) return 'display'
      if (category.id  === 33) return 'flower2'
      if (category.id  === 37) return 'hand-thumbs-up'  
      if (category.id  === 11) return 'tree'
      if (category.id  === 1029) return 'eyeglasses'
      if (category.id  === 14) return 'bug-fill'  
      if (category.id  === 28) return 'cup-straw' 
      if (category.id  === 17) return 'bicycle'  
      if (category.id  === 1005) return 'briefcase'
    } else if (network === 'youtube') {
      if (category.id  === 2) return 'pencil'
      if (category.id  === 1) return 'bug-fill'
      if (category.id  === 12) return 'bicycle'
      if (category.id  === 4) return 'stoplights'
      if (category.id  === 5) return 'flower1'
      if (category.id  === 6) return 'menu-button-wide-fill'
      if (category.id  === 21) return 'cpu-fill'
      if (category.id  === 13) return 'egg-fried'
      if (category.id  === 22) return 'trophy'
      if (category.id  === 7) return 'brush'
      if (category.id  === 9) return 'award'
      if (category.id  === 10) return 'house-door'
      if (category.id  === 15) return 'emoji-laughing'
      if (category.id  === 8) return 'search'
      if (category.id  === 23) return 'puzzle-fill'
      if (category.id  === 18) return 'patch-question'
      if (category.id  === 11) return 'camera-reels-fill'
      if (category.id  === 17) return 'music-note-beamed'
      if (category.id  === 19) return 'card-heading'
      if (category.id  === 16) return 'film'
      if (category.id  === 14) return 'plus-square'
      if (category.id  === 20) return 'calendar-day'
      if (category.id  === 3) return 'soundwave'
      if (category.id  === 24) return 'geo-alt-fill'
    } else if (network === 'tiktok') {
      if (category.id  === 19) return 'cloud'
      if (category.id  === 17) return 'heart'
      if (category.id  === 1) return 'bug-fill'
      if (category.id  === 12) return 'bicycle'
      if (category.id  === 3) return 'brush'
      if (category.id  === 4) return 'stoplights'
      if (category.id  === 9) return 'music-note-beamed'
      if (category.id  === 13) return 'cup-straw'
      if (category.id  === 5) return 'flower1'
      if (category.id  === 24) return 'award'
      if (category.id  === 6) return 'emoji-laughing'
      if (category.id  === 25) return 'trophy'
      if (category.id  === 10) return 'house-door'
      if (category.id  === 16) return 'tree'
      if (category.id  === 7) return 'search'
      if (category.id  === 14) return 'dice5'
      if (category.id  === 11) return 'camera-reels-fill'
      if (category.id  === 15) return 'plus-square'
      if (category.id  === 26) return 'geo-alt-fill'
      if (category.id  === 8) return 'stopwatch'
    }
  },
  getContentText(type) {
    if (type === 'photo') return 'Foto'
    if (type === 'video') return 'Video'
    if (type === 'story') return 'Story'
    if (type === 'streaming') return 'Streaming'
    if (type === 'instagram_tv') return 'Instagram tv'
    if (type === 'reel') return 'Reels'
    if (type === 'post') return 'Post'
    if (type === 'snap') return 'Snap' 
    return 'award'
  },
  getfrontName(index) {
    const icons = ['graph-up','broadcast-pin','bag','award','person-plus','music-note-beamed','brush','shield-check','file-lock','bookmark-star','heart']

    if (icons.indexOf(icons[index]) !== -1) {
      return icons[index]
    } else {
      return 'images'
    }
  },
  getIconWhite(network) {
    if (network === 'facebook') return 'icomoon-Facebook-2'
    if (network === 'instagram') return 'icomoon-instagram1'
    if (network === 'linkedin') return 'icomoon-Linkedin'
    if (network === 'twitch') return 'iconNetwork-twitch'
    if (network === 'twitter') return 'icomoon-Twitter-1'
    if (network === 'youtube') return 'icomoon-youtube'
    if (network === 'tiktok') return 'icomoon-tiktok'
    if (network === 'spotify') return 'iconNetwork-spotify'
    if (network === 'pinterest') return 'icomoon-pinterest2'
    if (network === 'blog') return 'iconNetwork-blogger'
    if (network === 'snapchat') return 'iconNetwork-snapchat-ghost'
  },
  getColor(network) {
    if (network === 'facebook') return '#3b5998'
    if (network === 'instagram') return '#d6249f'
    if (network === 'linkedin') return '#346297'
    if (network === 'twitch') return '#924DF7'
    if (network === 'twitter') return '#00acee'
    if (network === 'youtube') return '#c4302b'
    if (network === 'tiktok') return '#ff0650'
    if (network === 'spotify') return '#64D862'
    if (network === 'pinterest') return '#E73630'
    if (network === 'blog') return '#F0A23F'
    if (network === 'snapchat') return '#F5D90D'
  },
  getImageNetwork(network) {
    if (network === 'facebook') return require('@/assets/images/anlytics/facebook.png')
    if (network === 'instagram') return require('@/assets/images/anlytics/instagram.png')
    if (network === 'linkedin') return require('@/assets/images/anlytics/linkedin.png')
    if (network === 'twitch') return require('@/assets/images/anlytics/twitch.png')
    if (network === 'twitter') return require('@/assets/images/anlytics/twitter.png')
    if (network === 'youtube') return require('@/assets/images/anlytics/youtube.png')
    if (network === 'tiktok') return require('@/assets/images/anlytics/tiktok.png')
    if (network === 'spotify') return require('@/assets/images/anlytics/spotify.png')
    if (network === 'pinterest') return require('@/assets/images/anlytics/pinterest.png')
    if (network === 'blog') return require('@/assets/images/anlytics/blog.png')
    if (network === 'snapchat') return require('@/assets/images/anlytics/snapchat.png')
  },
  getImageNetworkColor(network) {
    if (network === 'facebook') return require("@/assets/images/icons/facebook2.svg")
    if (network === 'instagram') return require("@/assets/images/icons/instagram2.svg")
    if (network === 'linkedin') return require("@/assets/images/icons/linkedin2.svg")
    if (network === 'twitch') return require("@/assets/images/icons/twitch2.svg")
    if (network === 'twitter') return require("@/assets/images/icons/twitter2.svg")
    if (network === 'youtube') return require("@/assets/images/icons/youtube2.svg")
    if (network === 'tiktok') return require("@/assets/images/icons/tiktok2.svg")
    if (network === 'spotify') return require("@/assets/images/icons/spotify2.svg")
    if (network === 'pinterest') return require("@/assets/images/icons/pinterest2.svg")
    if (network === 'blog') return require("@/assets/images/icons/blog2.svg")
    if (network === 'snapchat') return require("@/assets/images/icons/snapchat2.svg")
  },
  getFollowers(size_audience) {
    const followers = {
      'nano': {
        'from': 1000,
        'to': 10000
      },
      'micro': {
        'from': 10000,
        'to': 50000
      },
      'medium': {
        'from': 50000,
        'to': 500000
      },
      'macro': {
        'from': 500000,
        'to': 1000000
      },
      'mega': {
        'from': 1000000,
        'to': null
      },
    }

    if (size_audience === null) 
      return {
        'from': null,
        'to': null
      }

    return followers[size_audience]
  },
  isVideo(url) {
    if (url === null) return ''

    if (url.split(':')[0] === 'blob') {
      return this.file.type.split('/')[0] === 'video'
    } else {
      const split = url.split(".");
      const last_one = split[split.length - 1];
      return last_one.toLowerCase() === "mp4" || last_one.toLowerCase() === "mov";
    }
  },
  checkNumber(str) {
    return !isNaN(str) && !isNaN(parseFloat(str))
  },
  trimText(str) {
    return str.trim()
  },
  isInvitation() {
    return window.location.href.includes('invitation')
  },
  isOOH() {
    return window.location.href.includes('outofhome')
  },
  getDropdownNetworks() {
    return  [
      {
        value: "tiktok",
        network: "Tiktok",
        icon: require("@/assets/images/icons/tiktok2.svg"),
      },
      {
        value: "spotify",
        network: "Spotify",
        icon: require("@/assets/images/icons/spotify2.svg"),
      },
      {
        value: "snapchat",
        network: "Snapchat",
        icon: require("@/assets/images/icons/snapchat2.svg"),
      },
      {
        value: "blog",
        network: "Blog",
        icon: require("@/assets/images/icons/blog2.svg"),
      },
      {
        value: "pinterest",
        network: "Pinterest",
        icon: require("@/assets/images/icons/pinterest2.svg"),
      },

      {
        value: "linkedin",
        network: "LinkedIn",
        icon: require("@/assets/images/icons/linkedin2.svg"),
      },
      {
        value: "youtube",
        network: "Youtube",
        icon: require("@/assets/images/icons/youtube2.svg"),
      },
      {
        value: "instagram",
        network: "Instagram",
        icon: require("@/assets/images/icons/instagram2.svg"),
      },
      {
        value: "twitter",
        network: "Twitter",
        icon: require("@/assets/images/icons/twitter2.svg"),
      },
      {
        value: "twitch",
        network: "Twitch",
        icon: require("@/assets/images/icons/twitch2.svg"),
      },
      {
        value: "facebook",
        network: "Facebook",
        icon: require("@/assets/images/icons/facebook2.svg"),
      },
    ]
  },

  getAvaibleData(obj) {
    let index = 0
    for (const property in obj) {
      if (obj[property].value && obj[property].value !== 0) {
        return {"performance": property.slice(0,-1), "value": obj[property].value, index}
      } else if ((obj[property].media_ids !== undefined && obj[property].media_ids.length !== 0)) {
        return {"performance": property.slice(0,-1), "value": obj[property].media_ids, index}
      }
      index += 1;
    }
    return {"performance": 0, "value": 0, index}
  },
  getPercentagePerformance(performance) {
    const op = Math.abs(100 / performance[performance.length - 1])
    const new_array = []
    performance.forEach((item, index) => {
      if (index !== performance.length - 1) {
        new_array.push((op * item).toFixed(2))
      }
    })
    return new_array
  },
  formatDuration(duration_string) {
    let a = duration_string.match(/\d+/g);
    if (duration_string.indexOf('M') >= 0 && duration_string.indexOf('H') === -1 && duration_string.indexOf('S') === -1) {
      a = [0, a[0], 0];
    }

    if (duration_string.indexOf('H') >= 0 && duration_string.indexOf('M') === -1) {
      a = [a[0], 0, a[1]];
    }
    if (duration_string.indexOf('H') >= 0 && duration_string.indexOf('M') === -1 && duration_string.indexOf('S') === -1) {
      a = [a[0], 0, 0];
    }

    let duration = 0;

    if (a.length === 3) {
      duration = duration + (parseInt(a[0]) * 3600);
      duration = duration + (parseInt(a[1]) * 60);
      duration = duration + (parseInt(a[2]));
    }

    if (a.length === 2) {
      duration = duration + (parseInt(a[0]) * 60);
      duration = duration + (parseInt(a[1]));
    }

    if (a.length === 1) {
      duration = duration + (parseInt(a[0]));
    }

    return ((duration - (duration %= 60)) / 60) + (9 < duration ? ':' : ':0') + duration
  },
  shortText(number_words, text) {
    const first_space = /^ /
    const last_space = / $/
    const many_spaces = /[ ]+/
    text = text.replace(first_space," ");
    text = text.replace(last_space,"");
    text = text.replace(many_spaces,"");
    const text_splited = text.split(' ').slice(0, number_words);
    return text_splited.join(' ')
    
  },
  getColorDotProfile(mark) {
    const obj = {
      low: 'bg-danger',
      below_average: "bg-danger",
      poor: 'bg-danger',
      fair: 'bg-danger',
      average: 'bg-warning',
      good: 'bg-success',
      very_good: 'bg-success',
      excellent: 'bg-success',
      none: "bg-secondary"
    }
    return obj[mark]
  },
  statusVariant(status) {
    const statusColor = {
      onboarding: "light-primary",
      approved: "light-success",
      published: "light-success",
      producing: "light-warning",
      trading: "light-warning",
      submitted: "light-info",
      rejected: "light-danger",
      low: 'light-danger',
      below_average: "light-danger",
      poor: 'light-danger',
      fair: 'light-danger',
      average: 'light-warning',
      good: 'light-success',
      very_good: 'light-success',
      excellent: 'light-success',
      none: "light-secondary"
    };    
    return statusColor[status];
  },
  statusVariantPaymentData(has_payment_data) {
    if (has_payment_data) return "light-success"
    else return "secondary"
  },
  usernameByUrl(url, domain) {
    const split_domain = url.split(domain)
    if (split_domain.length < 2) return ''

    const wihtout_params = split_domain[1].split('/')[0]

    const username = wihtout_params.split('?')[0]
    return username
  },
  getUsernameUrlYoutube(url) {
    let username = ''
    let is_channel = false
    if (url.includes('/channel/')) {
      username = this.usernameByUrl(url, 'youtube.com/channel/')
      is_channel = true
    } else {
      username = this.usernameByUrl(url, 'youtube.com/user/')
    }

    return [username, is_channel]
  },
  getSkinMode() {
    const { skin } = useAppConfig();
    return skin;
  }
}
