export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/loading-account',
    name: 'load-account',
    component: () => import('@/views/components/spinner/Spinner.vue')
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('@/views/status/status.vue')
  },
  
  {
    path: '/email-confirmation',
    name: 'email-confirm',
    component: () => import('@/views/pages/authentication/EmailConfirmation.vue'),
    meta: {
      resource: 'all',
      show_secunday_navbar: false,
      action: 'read'
    }
  }
]
