import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-analytics' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.path === '/roll' && JSON.parse(localStorage.userData).groups.length > 0) {
    next({ name: 'dashboard-analytics' });
  }
  if (to.path === '/pages/account-setting/General' && JSON.parse(localStorage.userData).groups.length === 0) {
    return next({ name: 'auth-roll' })
  }
  if (to.path === '/roll' && _.path === '/register') {
    return next()
  }

  if (to.path === '/dashboard' && isLoggedIn) {
    const user = JSON.parse(localStorage.userData)
    const role = user.main_group ? user.main_group.name : '';
    if (role === 'realty') next({ name: 'campaigns' })
  }
  if (to.path.split('/')[1] === 'perfil') {
    const has_session = localStorage.getItem('accessToken') !== null;
    if (!has_session) return next({ name: 'auth-perfil-public' , params: {network: to.params.network, alias: to.params.alias}})
  }

  if (to.path === '/monitor' && isLoggedIn) {
    const user = JSON.parse(localStorage.userData)
    if (!user.is_superuser) next({ name: 'dashboard-analytics' })
  }

  if (to.path === '/search') {
    const user = JSON.parse(localStorage.userData)
    const role = user.main_group && user.main_group.name ? user.main_group.name : '';
    if (role === 'realty') next({ name: 'realty-search' })
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData))
  }

  return next()
})

export default router
