const general = require('./en/general.json')
const profile = require('./en/profile.json')
const campaign = require('./en/campaign.json')
const dashboard = require('./en/dashboard.json')
const list = require('./en/list.json')
const login = require('./en/login.json')
const monitor = require('./en/monitor.json')
const register = require('./en/register.json')
const search = require('./en/search.json')
const settings = require('./en/setting.json')
const status = require('./en/status.json')
const leads = require('./en/leads.json')
const mediaKits = require('./en/mediaKits.json')
const campaignsOoh = require('./en/campaignsOoh.json')
const invitation = require('./en/invitation.json')
const creator = require('./en/creator.json')

export default  {
  ...general,
  ...profile,
  ...monitor,
  ...campaign,
  ...dashboard,
  ...list,
  ...login,
  ...monitor,
  ...settings,
  ...register,
  ...search,
  ...status,
  ...leads,
  ...mediaKits,
  ...campaignsOoh,
  ...invitation,
  ...creator,
}
