export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/mapas',
    name: 'mapas',
    component: () => import('@/views/pages/outofhome/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    }
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/account-company',
    name: 'account-company',
    component: () => import('@/views/pages/authentication/AcountCompany.vue'),
    meta: {
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/additional-information',
    name: 'additional-information',
    component: () => import('@/views/pages/authentication/AcountCompany.vue'),
    meta: {
      resource: 'Auth',
      show_secunday_navbar: false,
      action: 'read'
    }
  },
  {
    path: '/adios',
    name: 'good-bye',
    component: () => import('@/views/pages/account-setting/GoodBye.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: () => import('@/views/pages/account-setting/Unsubscribe.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  /*{
    path: '/pages/authentication/login-v1',
    name: 'auth-login-v1',
    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/login-v2',
    name: 'auth-login-v2',
    component: () => import('@/views/pages/authentication/Login-v2.vue'),
    meta: {
      layout: 'full'
    }
  },*/
  {
    path: '/pages/authentication/register-v1',
    name: 'auth-register-v1',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/invitation/:uuidInfluencer/:uuid',
    name: 'invitation',
    component: () => import('@/views/pages/invitation/invitation.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/invitationDetails/:uuidInfluencer/:uuid',
    name: 'invitationDetails',
    component: () => import('@/views/pages/invitation/invitationDetails.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/external/:slug/:network/:username',
    name: 'invitation-external',
    component: () => import('@/views/pages/invitation/invitation.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/external/details/:slug/:network/:username',
    name: 'new-invitation-external',
    component: () => import('@/views/pages/invitation/invitationDetails.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/external-new/details/:slug/:network/:username',
    name: 'new-detail-invitation-external',
    component: () => import('@/views/pages/invitation/newInvitationDetail.vue'),
    meta: {
      resource: 'Auth'
    }
  },
  {
    path: '/pages/authentication/register-v2',
    name: 'auth-register-v2',
    component: () => import('@/views/pages/authentication/Register-v2.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/pages/authentication/forgot-password-v1',
    name: 'auth-forgot-password-v1',
    component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/forgot-password-v2',
    name: 'auth-forgot-password-v2',
    component: () => import('@/views/pages/authentication/ForgotPassword-v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/pages/authentication/reset-password-v1',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/reset-password-v2',
    name: 'auth-reset-password-v2',
    component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/account-setting/:section',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/pages/account-setting/',
    name: 'pages-account-setting-without-section',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/leads',
    name: 'leads',
    component: () => import('@/views/pages/leads/Leads.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/my-work/:section',
    name: 'my_work-without-section',
    component: () => import('@/views/pages/creator/myWork.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/home-search',
    name: 'home_search',
    component: () => import('@/views/pages/creator/homeSearch.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/my-work/',
    name: 'my_work',
    component: () => import('@/views/pages/creator/myWork.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/connection/:network/:username',
    name: 'detail_connection',
    component: () => import('@/views/pages/creator/detailConnection.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/category/:slug?',
    name: 'step_category',
    component: () => import('@/views/pages/creator/stepper/category.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/sub-category/:slug?',
    name: 'step_subcategory',
    component: () => import('@/views/pages/creator/stepper/subcategory.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/add-connection/:slug?',
    name: 'step_add_connection',
    component: () => import('@/views/pages/creator/stepper/enrichmentConnection.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/title/:slug?',
    name: 'step_title',
    component: () => import('@/views/pages/creator/stepper/stepTitle.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/description/:slug?',
    name: 'step_description',
    component: () => import('@/views/pages/creator/stepper/description.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/location/:slug?',
    name: 'step_location',
    component: () => import('@/views/pages/creator/stepper/location.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/deals/:slug?',
    name: 'step_deals',
    component: () => import('@/views/pages/creator/stepper/deals.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/samples/:slug?',
    name: 'step_samples',
    component: () => import('@/views/pages/creator/stepper/samples.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/options/:slug?',
    name: 'step_options',
    component: () => import('@/views/pages/creator/stepper/options.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/requests/:slug?',
    name: 'step_requests',
    component: () => import('@/views/pages/creator/stepper/requests.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/step/preview/:slug',
    name: 'step_preview',
    component: () => import('@/views/pages/creator/stepper/preview.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/pages/profile',
    name: 'pages-profile',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'Profile',
          active: true
        }
      ]
    }
  },

  {
    path: '/pages/faq',
    name: 'pages-faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'FAQ',
          active: true
        }
      ]
    }
  },
  {
    path: '/monitor',
    name: 'pages-knowledge-base',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBase.vue'),
  },
  {
    path: '/pages/knowledge-base/:category',
    name: 'pages-knowledge-base-category',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategory.vue'),
    meta: {
      pageTitle: 'Category',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'Knowledge Base',
          to: '/pages/Knowledge-base'
        },
        {
          text: 'Category',
          active: true
        }
      ],
      navActiveLink: 'pages-knowledge-base'
    }
  },
  {
    path: '/pages/knowledge-base/:category/:slug',
    name: 'pages-knowledge-base-question',
    component: () => import('@/views/pages/Knowledge-base/KnowledgeBaseCategoryQuestion.vue'),
    meta: {
      pageTitle: 'Question',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'Knowledge Base',
          to: '/pages/Knowledge-base'
        },
        {
          text: 'Category',
          to: '/pages/Knowledge-base/category'
        },
        {
          text: 'Question',
          active: true
        }
      ],
      navActiveLink: 'pages-knowledge-base'
    }
  },
  {
    path: '/pages/pricing',
    name: 'pages-pricing',
    component: () => import('@/views/pages/pricing/Pricing.vue')
  },
  {
    path: '/pages/blog/list',
    name: 'pages-blog-list',
    component: () => import('@/views/pages/blog/BlogList.vue'),
    meta: {
      pageTitle: 'Blog List',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'Blog'
        },
        {
          text: 'List',
          active: true
        }
      ]
    }
  },
  {
    path: '/pages/blog/:id',
    name: 'pages-blog-detail',
    component: () => import('@/views/pages/blog/BlogDetail.vue'),
    meta: {
      pageTitle: 'Blog Detail',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'Blog'
        },
        {
          text: 'Detail',
          active: true
        }
      ]
    }
  },
  {
    path: '/pages/blog/edit/:id',
    name: 'pages-blog-edit',
    component: () => import('@/views/pages/blog/BlogEdit.vue'),
    meta: {
      pageTitle: 'Blog Edit',
      breadcrumb: [
        {
          text: 'Pages'
        },
        {
          text: 'Blog'
        },
        {
          text: 'Edit',
          active: true
        }
      ]
    }
  }
]
