export default [
  {
    path: '/terminos-y-condiciones',
    name: 'terms-conditions',
    component: () => import('@/views/pages/authentication/Terms-and-condition.vue'),
    meta: {
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Dashboard.vue'),
    meta: {
      resource: 'all',
      action: 'read'
    }
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue')
  },
  {
    path: '/campaigns/',
    name: 'campaigns',
    component: () => import('@/views/campaings/CampaignV2.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/lists',
    name: 'lists',
    component: () => import('@/views/pages/lists/Lists.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/realty/campaign/:uuid',
    name: 'realty-campaign',
    component: () => import('@/views/pages/outofhome/Campaign.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/realty/maps',
    name: 'realty_maps',
    component: () => import('@/views/pages/outofhome/maps/maps.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/campaign/:uuid',
    name: 'campaign',
    component: () => import('@/views/campaings/campaign.vue'),
    meta: {
      resource: 'view_campaign',
      action: 'manage'
    }
  },
  {
    path: '/lead/:slug',
    name: 'campaign-leads',
    component: () => import('@/views/campaings/CampaignLead.vue')
  },
  {
    path: '/brief/:uuid',
    name: 'brief',
    component: () => import('@/views/campaings/brief.vue')
  },
  {
    path: '/requirements/:uuid',
    name: 'requirements-netowrks',
    component: () => import('@/views/campaings/requirementsView.vue')
  },
  {
    path: '/campaignWhite',
    name: 'campaignWhite',
    component: () => import('@/views/campaings/campaignWhite.vue')
  },
  {
    path: '/roll',
    name: 'auth-roll',
    component: () => import('@/views/pages/authentication/Roll.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/search',
    name: 'auth-search',
    component: () => import('@/views/buscador/buscador.vue'),
    meta: {
      resource: 'view_searcher',
      action: 'manage'
    }
  },
  {
    path: '/realty/search',
    name: 'realty-search',
    component: () => import('@/views/pages/outofhome/buscador.vue'),
    meta: {
      resource: 'view_searcher',
      action: 'manage'
    }
  },
  {
    path: '/perfil/:network/:alias',
    name: 'auth-perfil',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      resource: 'view_profile',
      action: 'manage',
    }
  },
  {
    path: '/perfil-public/:network/:alias',
    name: 'auth-perfil-public',
    component: () => import('@/views/pages/profile/preview/MainProfilePreview.vue'),
    meta: {
      resource: 'Auth'
    }
  },
  {
    path: '/media-kits',
    name: 'media-kits',
    component: () => import('@/views/media-kits/MediaKits.vue')
  },
  {
    path: '/resumen/perfil/:network/:alias',
    name: 'auth-perfil',
    component: () => import('@/views/pages/profile/summary.vue'),
    meta: {
      layout: 'full',
      resource: 'view_profile',
      action: 'manage'
    }
  }
]
